import React from "react";

import Layout from "../../components/layout";
import {DownloadBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import ReleaseHistory from "../../components/releaseHistory";

const pageTitle = "Release History";

const Page = () => (
    <Layout pageTitle={pageTitle}
            pageNav={<Breadcrumb activeName={pageTitle}/>}>
        <p>Below is the complete release notes log:</p>
        <ReleaseHistory/>
    </Layout>
);

export default Page;
